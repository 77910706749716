import React, { useState, useEffect } from 'react';
import { Bell, BellOff } from 'lucide-react';

const applicationServerKey = 'BNbvc0IoiDE-EBHzfI5z3AvYyvvBbPmEJ2J7tOUyLvQT7Ah6GnOELWKoUOPqkk11zpfRLZCUW-eiiMmGtxAELEI';

const PushNotificationMenuItem = () => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState('');

    useEffect(() => {
        const checkSubscription = async () => {
            if ('serviceWorker' in navigator && 'PushManager' in window) {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.getSubscription();
                setIsSubscribed(!!subscription);
            }
        };

        checkSubscription();
    }, []);

    const toggleSubscription = async () => {
        if (isSubscribed) {
            await unsubscribeUser();
        } else {
            await subscribeUser();
        }
    };

    const subscribeUser = async () => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            try {
                const permission = await Notification.requestPermission();
                if (permission !== 'granted') {
                    setNotificationStatus('Permission refusée');
                    return;
                }

                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                });

                await fetch('/api/api/subscribe', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(subscription),
                });

                setIsSubscribed(true);
                setNotificationStatus('Abonné');
            } catch (err) {
                console.error('Échec de la souscription:', err);
                setNotificationStatus('Erreur');
            }
        }
    };

    const unsubscribeUser = async () => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            try {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.getSubscription();

                if (subscription) {
                    await subscription.unsubscribe();

                    await fetch('/api/api/unsubscribe', {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(subscription),
                    });

                    setIsSubscribed(false);
                    setNotificationStatus('Désabonné');
                }
            } catch (err) {
                console.error('Échec de la désinscription:', err);
                setNotificationStatus('Erreur');
            }
        }
    };

    return (
        <div onClick={toggleSubscription} className="flex items-center justify-between w-full px-2 py-2 text-sm">
            <span>{isSubscribed ? 'Désactiver les notifications' : 'Activer les notifications'}</span>
            {isSubscribed ? <Bell size={18} /> : <BellOff size={18} />}
        </div>
    );
};

export default PushNotificationMenuItem;