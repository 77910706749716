import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Paper,
    Box,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function QRCodeScanner({ onStudentScanned ,buttonName} ) {
    const [open, setOpen] = useState(false);
    const [scanResult, setScanResult] = useState(null);
    const [scanSuccess, setScanSuccess] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [lastScannedData, setLastScannedData] = useState(null);
    const scannerRef = useRef(null);
    const messageTimeoutRef = useRef(null);
    const lastScanTimeRef = useRef(0);
    const lastScannedQRRef = useRef(null);

    useEffect(() => {
        if (open) {
            startScanner();
        } else {
            stopScanner();
        }

        return () => {
            stopScanner();
            clearMessageTimeout();
        };
    }, [open]);

    const startScanner = async () => {
        try {
            const devices = await Html5Qrcode.getCameras();
            const rearCamera = devices.find(device => device.label.toLowerCase().includes('back')) || devices[0];

            scannerRef.current = new Html5Qrcode("reader");
            await scannerRef.current.start(
                rearCamera.id,
                {
                    fps: 10,
                    qrbox: { width: 250, height: 250 }
                },
                handleSuccess,
                handleError
            );
        } catch (error) {
            console.error("Failed to start scanner:", error);
            showMessage("Impossible de démarrer le scanner. Veuillez réessayer.", false);
        }
    };

    const stopScanner = () => {
        if (scannerRef.current) {
            scannerRef.current.stop().catch(error => console.error("Failed to stop scanner:", error));
            scannerRef.current = null;
        }
    };

    const handleSuccess = useCallback((decodedText) => {
        const currentTime = Date.now();
        if (decodedText === lastScannedQRRef.current && currentTime - lastScanTimeRef.current < 2000) {
            return; // Ignore if same QR code scanned within 2 seconds
        }

        try {
            const parsedData = JSON.parse(decodedText);
            if (parsedData.provider === "Ecole du Sacré Cœur") {
                setScanResult(parsedData);
                setScanSuccess(true);
                setLastScannedData(parsedData);
                showMessage(`${parsedData.name} ${parsedData.firstName} scanné avec succès`, true);
                vibrate();
                onStudentScanned(parsedData); // Inform parent component
                lastScannedQRRef.current = decodedText;
                lastScanTimeRef.current = currentTime;
            } else {
                throw new Error("QR code invalide : il ne provient pas de l'école");
            }
        } catch (error) {
            console.error('Erreur lors de la validation du QR code:', error);
            setScanSuccess(false);
            showMessage(error.message, false);
        }
    }, [onStudentScanned]);

    const handleError = (error) => {
        console.error('Erreur de scan du QR code:', error);
    };

    const showMessage = (message, isSuccess) => {
        clearMessageTimeout();
        if (isSuccess) {
            setLastScannedData({ name: message });
            setErrorMessage('');
        } else {
            setLastScannedData(null);
            setErrorMessage(message);
        }
        messageTimeoutRef.current = setTimeout(() => {
            setLastScannedData(null);
            setErrorMessage('');
        }, 2000);
    };

    const clearMessageTimeout = () => {
        if (messageTimeoutRef.current) {
            clearTimeout(messageTimeoutRef.current);
        }
    };

    const vibrate = () => {
        if (navigator.vibrate) {
            navigator.vibrate(200);
        }
    };

    const handleScan = () => {
        setOpen(true);
        setScanSuccess(null);
        setScanResult(null);
        setErrorMessage('');
        setLastScannedData(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Button variant="contained" onClick={handleScan}>
                {buttonName}
            </Button>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Scanner un QR Code</DialogTitle>
                <DialogContent>
                    <div id="reader" style={{ width: '100%' }}></div>
                    {lastScannedData && (
                        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                            <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                            <Typography>
                                {lastScannedData.name}
                            </Typography>
                        </Box>
                    )}
                    {errorMessage && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default QRCodeScanner;