import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling from "@dimitrk/qr-code-styling";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    CircularProgress, Modal
} from '@mui/material';
import jsPDF from 'jspdf';

const CARD_WIDTH = 85.6; // mm
const CARD_HEIGHT = 53.98; // mm

function StudentQRCodeList() {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const qrCodeRefs = useRef({});
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const schoolYear = currentDate.getMonth() < 6 ? `${currentYear-1}-${currentYear}` : `${currentYear}-${currentYear+1}`;

    useEffect(() => {
        fetchStudents();
    }, []);

    const fetchStudents = async () => {
        try {
            const response = await fetch('/api/usersStudents', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const formattedData = data.map(user => ({
                ID: user.ID,
                name: user.name,
                firstName: user.firstName,
                niveau: user.niveau,
            }));
            setStudents(formattedData.sort((a, b) => a.name.localeCompare(b.name)));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Une erreur est survenue lors du chargement des données');
            setLoading(false);
        }
    };

    const generateQRCode = (student) => {
        return new QRCodeStyling({
            width: 300,
            height: 300,
            margin: 0,
            data: JSON.stringify({
                provider: "Ecole du Sacré Cœur",
                name: student.name,
                firstName: student.firstName,
                id: student.ID,
                schoolYear: schoolYear
            }),
            image: "/favicon.ico",
            qrOptions: {
                typeNumber: "0",
                mode: "Byte",
                errorCorrectionLevel: "Q"
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.4,
                margin: 0
            },
            dotsOptions: {
                type: "rounded",
                color: "#c0c0c0"
            },
            backgroundOptions: {
                color: "#ffffff"
            },
            cornersSquareOptions: {
                type: "extra-rounded",
                color: "#1303d1"
            },
            cornersDotOptions: {
                type: "",
                color: "#c0c0c0"
            }
        });
    };

    const getQRCodeDataUrl = (qrCode) => {
        return new Promise((resolve) => {
            qrCode.getRawData().then(blob => {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result);
                }
                reader.readAsDataURL(blob);
            });
        });
    };

    const generatePDF = async () => {
        setGeneratingPDF(true);
        try {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 2;
            const cardsPerRow = 3;
            const cardsPerColumn = 6;

            // Calcul des dimensions des cartes
            const cardWidth = ((pageWidth - (margin * (cardsPerRow + 1))) / cardsPerRow)-15;
            const cardHeight = (pageHeight - (margin * (cardsPerColumn + 1))) / cardsPerColumn;

            // Définir une taille fixe pour tous les QR codes
            const qrCodeSize = Math.min(cardWidth, cardHeight) - 10; // Taille fixe pour tous les QR codes

            for (let i = 0; i < students.length; i++) {
                if (i > 0 && i % (cardsPerRow * cardsPerColumn) === 0) {
                    pdf.addPage();
                }

                const row = Math.floor((i % (cardsPerRow * cardsPerColumn)) / cardsPerRow);
                const col = i % cardsPerRow;

                const x = margin + col * (cardWidth + margin);
                const y = margin + row * (cardHeight + margin);

                // Dessiner le contour de la carte
                pdf.rect(x , y , cardWidth , cardHeight );

                // Ajouter le nom de l'étudiant
                pdf.setFontSize(8);
                pdf.text(`${students[i].name} ${students[i].firstName}`, x + cardWidth / 2, y + 4, { align: 'center' });

                // Ajouter le QR code
                const qrCode = generateQRCode(students[i]);
                const qrCodeDataUrl = await getQRCodeDataUrl(qrCode);
                const qrCodeX = x + (cardWidth - qrCodeSize) / 2;
                const qrCodeY = y + 6;
                pdf.addImage(qrCodeDataUrl, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

                // Ajouter l'année scolaire
                pdf.setFontSize(6);
                pdf.text(schoolYear, x + cardWidth / 2, y + cardHeight - 2, { align: 'center' });
            }

            pdf.save('student_qr_codes.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
            // Vous pouvez ajouter ici un message d'erreur pour l'utilisateur
        } finally {
            setGeneratingPDF(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }
    const printIndividualCard = async (student) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 2;
        const cardsPerRow = 3;
        const cardsPerColumn = 6;

        // Calcul des dimensions des cartes
        const cardWidth = ((pageWidth - (margin * (cardsPerRow + 1))) / cardsPerRow)-15;
        const cardHeight = (pageHeight - (margin * (cardsPerColumn + 1))) / cardsPerColumn;

        // Définir une taille fixe pour tous les QR codes
        const qrCodeSize = Math.min(cardWidth, cardHeight) - 10; // Taille fixe pour tous les QR codes

        const x = 10;
        const y = 10;

        // Dessiner le contour de la carte
        pdf.rect(x , y , cardWidth , cardHeight );

        // Ajouter le nom de l'étudiant
        pdf.setFontSize(8);
        pdf.text(`${student.name} ${student.firstName}`, x + cardWidth / 2, y + 4, { align: 'center' });

        // Ajouter le QR code
        const qrCode = generateQRCode(student);
        const qrCodeDataUrl = await getQRCodeDataUrl(qrCode);
        const qrCodeX = x + (cardWidth - qrCodeSize) / 2;
        const qrCodeY = y + 6;
        pdf.addImage(qrCodeDataUrl, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        // Ajouter l'année scolaire
        pdf.setFontSize(6);
        pdf.text(schoolYear, x + cardWidth / 2, y + cardHeight - 2, { align: 'center' });

        pdf.autoPrint();
        window.open(pdf.output('bloburl'), '_blank');
    };


    return (
        <>
            <Button onClick={generatePDF} variant="contained" color="primary" style={{marginBottom: '20px'}}>
                Générer PDF
            </Button>
            <Grid container spacing={2}>
                {students.map((student) => (
                    <Grid item key={student.ID} xs={12} sm={6} md={4} lg={3}>
                        <Card
                            sx={{
                                width: '100%',
                                height: '100%',
                                margin: 'auto',
                                pageBreakInside: 'avoid'
                            }}
                        >
                            <CardContent sx={{ height: '100%', padding: '8px !important', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle2" component="div" textAlign="center">
                                    {student.name} {student.firstName}
                                </Typography>
                                <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                                    <div ref={el => {
                                        if (el && !qrCodeRefs.current[student.ID]) {
                                            qrCodeRefs.current[student.ID] = generateQRCode(student);
                                            qrCodeRefs.current[student.ID].append(el);
                                        }
                                    }} />
                                </Box>
                                <Typography variant="caption" component="div" textAlign="center">
                                    {schoolYear}
                                </Typography>
                                <Button onClick={() => printIndividualCard(student)} variant="outlined" size="small" style={{marginTop: '5px'}}>
                                    Imprimer cette carte
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Modal
                open={generatingPDF}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Génération du PDF en cours
                    </Typography>
                    <CircularProgress />
                </Box>
            </Modal>
        </>
    );
}

export default StudentQRCodeList;