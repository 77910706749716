import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {NotificationProvider} from "./NotificationContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NotificationProvider>
   <App/>
    </NotificationProvider>
);
reportWebVitals();
reportWebVitals();
document.title = 'École du Sacré-Coeur';

// Enregistrement du service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then((registration) => {
                console.log('Service Worker enregistré avec succès:', registration.scope);
            })
            .catch((error) => {
                console.log('Échec de l\'enregistrement du Service Worker:', error);
            });
    });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        // Nouvelle version du service worker activée
        console.log('Nouvelle version disponible. Mise à jour en cours...');
        window.location.reload();
    });
}