import React, { useState, useEffect } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, CircularProgress } from '@mui/material';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
const France = require("flatpickr/dist/l10n/fr.js").default.fr;

const AuditLogSearch = () => {
    const [studentId, setStudentId] = useState('');
    const [date, setDate] = useState(null);
    const [auditLogs, setAuditLogs] = useState([]);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchStudents();
    }, []);

    const fetchStudents = async () => {
        try {
            setLoading(true);
            const response = await fetch('/api/usersStudents', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (!response.ok) {
                throw new Error('Erreur lors du chargement des étudiants');
            }
            const data = await response.json();
            console.log('Étudiants chargés:', data); // Log pour le débogage
            // Tri des étudiants par nom
            const sortedStudents = data.sort((a, b) => a.name.localeCompare(b.name));
            setStudents(sortedStudents);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors du chargement des étudiants:', error);
            setError(error.message);
            setLoading(false);
        }
    };

    const handleStudentChange = (event) => {
        console.log('Étudiant sélectionné:', event.target.value); // Log pour le débogage
        setStudentId(event.target.value);
    };

    const handleSearch = async () => {
        if (!studentId || !date) {
            setError('Veuillez sélectionner un étudiant et une date');
            return;
        }

        try {
            setLoading(true);
            // Correction pour s'assurer que la date est correcte
            const formattedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
                .toISOString()
                .split('T')[0];

            const response = await fetch(`/api/historical?student_id=${studentId}&date=${formattedDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la recherche');
            }

            const data = await response.json();
            console.log('Résultats de la recherche:', data);
            setAuditLogs(data);
        } catch (error) {
            console.error('Erreur lors de la recherche:', error);
            setError('Une erreur est survenue lors de la recherche');
        } finally {
            setLoading(false);
        }
    };

    const getLogType = (dataAfter) => {
        try {
            const parsedData = JSON.parse(dataAfter);
            return parsedData.type || "NORMAL";
        } catch (error) {
            console.error('Erreur lors du parsing de data_after:', error);
            return "NORMAL";
        }
    };


    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return (
        <div>
            <TextField
                select
                label="Étudiant"
                value={studentId}
                onChange={handleStudentChange}
                fullWidth
                margin="normal"
                placeholder="Sélectionnez un étudiant" // Ajout d'un placeholder
                error={!studentId} // Afficher une erreur si aucun étudiant n'est sélectionné
                helperText={!studentId ? "Veuillez sélectionner un étudiant" : ""} // Aide contextuelle en cas d'erreur
            >
                {students.length > 0 ? (
                    students.map((student) => (
                        <MenuItem key={student.ID} value={student.ID}>
                            {student.firstName} {student.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>Aucun étudiant disponible</MenuItem>
                )}
            </TextField>


            <Flatpickr
                value={date}
                onChange={([selectedDate]) => {
                    console.log('Date sélectionnée:', selectedDate); // Log pour le débogage
                    setDate(selectedDate);
                }}
                options={{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    placeholder: 'Sélectionnez une date',
                    allowInput: true,
                    locale: France
                }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                style={{ marginTop: '20px' }}
                disabled={!studentId || !date}
            >
                Rechercher
            </Button>


            {auditLogs.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Table</TableCell>
                                <TableCell>Opération</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Date de création</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auditLogs.map((log) => (
                                <TableRow key={log.id}>
                                    <TableCell>{log.id}</TableCell>
                                    <TableCell>{log.email}</TableCell>
                                    <TableCell>{log.table_name}</TableCell>
                                    <TableCell>{log.operation}</TableCell>
                                    <TableCell>{getLogType(log.data_after)}</TableCell>
                                    <TableCell>{new Date(log.created_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default AuditLogSearch;