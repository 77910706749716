import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoginPage from './login';
import Dashboard from './dashboard';
import './App.css';
import DashboardAdmin from "./dashboardAdmin";
import AdminPanel from "./AdminPanel";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./resetPassword";
import InstallPWA from "./InstallPWA";
import PushManager from "./PushManager";
import PushNotificationManager from "./PushManager";
process.env.TZ = 'Europe/Paris';
function App() {
    return (
        <>
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />}/>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/*" element={<ResetPassword />} />
                <Route path="/dashboardAdmin/*" element={<PrivateRoute role="ATSEM"><DashboardAdmin /></PrivateRoute>} />
                <Route path="/dashboard/*"  element={<PrivateRoute role="parent"><Dashboard /></PrivateRoute>} />
                {/* <Route path="/dashboardAdmin/*" element={<DashboardAdmin />} />
                <Route path="/dashboard /*" element={<Dashboard />} /> */}
                <Route path="/admin/*"  element={<AdminPanel />} />
            </Routes>
        </Router>
    <InstallPWA />
        </>
    );
}
export default App;
